import {
  CollapseSectionButton,
  PlaybackControls,
  ResizableContainer,
  SidebarTextRenderer,
  StepDetailsDrawer,
} from '@assemblio/frontend/components';
import { Hierarchy } from '@assemblio/frontend/hierarchy';
import { useEditorShortcuts, useShortcuts } from '@assemblio/frontend/hooks';
import { Sequencer } from '@assemblio/frontend/sequencer';
import { CanvasController, useUIStore } from '@assemblio/frontend/stores';
import { ResizableContainerRef } from '@assemblio/frontend/types';
import { Viewport, ViewportControls } from '@assemblio/frontend/viewport';
import { LoadingOverlay, Paper } from '@mantine/core';
import { RootState } from '@react-three/fiber';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import classes from './Assembler.module.scss';

export function Assembler() {
  const [initialized, setInitialized] = useState(false);

  const machine = useUIStore((state) => state.machine);
  const showSidebarTextRenderer = useUIStore((state) => state.showSidebarTextRenderer);

  useEffect(() => {
    if (machine) {
      machine.start();
    }
  }, [machine]);

  const [rootState, setRootState] = useState<RootState | undefined>();

  const [hierarchyCollapsed, setHierarchyCollapsed] = useState(false);
  const [sequencerCollapsed, setSequencerCollapsed] = useState(false);

  const hierarchyDiv = useRef<HTMLDivElement>(null);
  const sequencerDiv = useRef<HTMLDivElement>(null);
  const hierarchyContainer = useRef<ResizableContainerRef>(null);
  const sequencerContainer = useRef<ResizableContainerRef>(null);

  useShortcuts();
  useEditorShortcuts(hierarchyContainer, sequencerContainer);

  useLayoutEffect(() => {
    if (rootState) {
      if (!initialized) {
        setInitialized(true);
      }
    }
  }, [rootState, initialized]);

  return (
    <div className={classes.viewportContainer}>
      <LoadingOverlay visible={!initialized} />
      <Viewport
        onCreated={(rootState) => {
          setRootState(rootState);
          CanvasController.setCamera(rootState.camera);
        }}
      />
      <div className={classes.controlsAnchor} style={{ zIndex: 5 }}>
        <div ref={hierarchyDiv} className={classes.sidebar}>
          <ResizableContainer
            containerRef={hierarchyDiv}
            maxWidthMargin={100}
            resizeHandle={'e'}
            ref={hierarchyContainer}
            onResize={(collapsed) => {
              setHierarchyCollapsed(collapsed);
            }}
          >
            {/*<Paper p="sm" className={classes.sidebarPaper}>*/}
            <Hierarchy />
            {/*</Paper>*/}
          </ResizableContainer>
          <CollapseSectionButton
            collapsed={hierarchyCollapsed}
            onClick={() => {
              hierarchyContainer.current?.toggle();
              setHierarchyCollapsed(!hierarchyCollapsed);
            }}
          />
        </div>
        <div className={classes.controlsContainer}>
          <div className={classes.viewportControls}>
            <ViewportControls />
          </div>
          <PlaybackControls />
          {showSidebarTextRenderer && <SidebarTextRenderer />}
          <StepDetailsDrawer />
        </div>

        <div ref={sequencerDiv} className={classes.sidebar}>
          <ResizableContainer
            containerRef={sequencerDiv}
            maxWidthMargin={300}
            resizeHandle={'w'}
            ref={sequencerContainer}
          >
            <Paper className={classes.sidebarPanel}>
              <Sequencer />
            </Paper>
          </ResizableContainer>
          <CollapseSectionButton
            collapsed={sequencerCollapsed}
            right
            onClick={() => {
              sequencerContainer.current?.toggle();
              setSequencerCollapsed(!sequencerCollapsed);
            }}
          />
        </div>
      </div>
    </div>
  );
}
