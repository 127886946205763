import { useModelStore, useSettingsStore, useUIStore } from '@assemblio/frontend/stores';
import { useThree } from '@react-three/fiber';
import { useEffect, useMemo } from 'react';
import { Box3 } from 'three';
import { shallow } from 'zustand/shallow';
import { CameraControls } from './CameraControls';
import { useFramer } from './Framer';
import { Grid } from './Grid';
import { LineRenderer } from './LineRenderer';
import { ImperativeModel } from './Model/ImperativeModel';
import { ObjectControls } from './ObjectControls';
import { SelectionControls } from './SelectionControls';
import { Surface } from './Surface';

export const CanvasElements = () => {
  const model = useModelStore((state) => state.model);

  const { gl } = useThree();
  const { isGridEnabled, lineWidth, lineColor } = useSettingsStore(
    (state) => ({
      isGridEnabled: state.viewport.grid.enabled,
      lineWidth: state.viewport.grid.thickness,
      lineColor: state.viewport.grid.color,
    }),
    shallow
  );

  const extent = useMemo(() => {
    const bounds = new Box3().setFromObject(model.scene);
    return bounds.max.length() * 3; // Three times the max extent of the model, to allow for room to move things
  }, [model]);

  useFramer();

  useEffect(() => {
    const canvas = gl.domElement;

    // Prevent context menu on right click
    const handleContextMenu = (event: MouseEvent) => {
      event.preventDefault();
    };

    canvas.addEventListener('contextmenu', handleContextMenu);

    return () => {
      canvas.removeEventListener('contextmenu', handleContextMenu);
    };
  }, [gl]);

  return (
    <>
      <directionalLight position={[0, 10, 0]} intensity={1.5} />
      <ambientLight intensity={2} color={0xffffff} />
      {isGridEnabled && <Grid radius={20} spacing={1} lineWidth={lineWidth} lineColor={lineColor} />}

      {model ? <ImperativeModel gltf={model} /> : null}

      <CameraControls />
      <ObjectControls />
      <SelectionControls />
      <LineRenderer />
      <Surface extent={extent} />
    </>
  );
};
